import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints } from '../../utils/styles'

export const Wrapper = styled.div`
  margin-bottom: 1.45rem;
`

export const Container = styled.div`
  padding: 2rem 2rem 2rem;
  margin: 0 auto;
  max-width: 1080px;
  position: relative;
  z-index: 50;
  @media (min-width: ${breakpoints.l}px) {
    padding: 6rem 2rem 2rem;
  }
  .theme-light & {
    z-index: 10;
  }
`

export const LogoWrap = styled.div`
  max-width: 78rem;
  margin: 0 auto 2rem;
  path {
    .theme-light & {
      fill: white;
    }
  }
`

export const MenuWrap = styled.div`
  color: black;
  .theme-light & {
    color: white;
  }
  &:first-of-type {
    border-bottom: 2px solid white;
  }
  > ul {
    list-style: none;
    padding: 0.5rem 0rem;
    margin: 0 auto;
    max-width: 590px;
    display: flex;
    justify-content: space-between;
    @media (min-width: ${breakpoints.l}px) {
      padding: 0.5rem 2rem;
    }
    > li {
      > ul {
        position: absolute;
        top: calc(100% + 1rem);
        left: 0;
        background-color: #525252;
        border: 1px solid black;
        border-color: black;
        background-color: #ddd;
        margin: 0;
        .theme-light & {
          border-color: white;
          background-color: #525252;
        }
        > li {
          padding: 0.5rem 1rem;
        }
        li {
          @media (min-width: ${breakpoints.m}px) {
            font-size: 1.6rem;
          }
        }
      }
    }
    ul {
      /* position: absolute;
      top: 100%;
      left: 0; */
      padding: 0;
      margin: 0.5em 0 1em;
      list-style: none;
    }
  }
  li {
    font-size: 1.4rem;
    text-transform: uppercase;
    position: relative;
    @media (min-width: ${breakpoints.m}px) {
      font-size: 1.8rem;
    }
  }
  a {
    color: black;
    text-decoration: none;
    .theme-light & {
      color: white;
    }
  }
`

export const Opener = styled.span`
  display: block;
  position: relative;
  padding-right: 2rem;
  cursor: pointer;
  @media (min-width: ${breakpoints.m}px) {
    padding-right: 3rem;
  }
  svg {
    position: absolute;
    right: 0;
  }
`

export const ToolBar = styled.ul`
  list-style: none;
  margin: 0;
  padding: 2rem 2rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  @media (min-width: ${breakpoints.l}px) {
    position: fixed;
    right: 4rem;
    top: 4rem;
  }
  li {
    margin: 0 1rem;
    @media (min-width: ${breakpoints.s}px) {
      margin: 0 0 0 1rem;
    }
  }
`

export const CartCounter = styled.span`
  background-color: white;
  color: #663399;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 1.2rem;
  float: right;
  margin: -10px;
  z-index: 20;
`
