import React, { useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql, Link } from 'gatsby'
import styled from '@emotion/styled'

import ContextProvider from '~/provider/ContextProvider'

import { GlobalStyle } from '~/utils/styles'

import {
  Wrapper,
  FooterMenu,
  TopLeftBorder,
  BottomRightBorder,
  Ribbon,
} from './styles'

import Navigation from '~/components/Navigation'

const Layout = props => {
  return (
    <ContextProvider>
      <GlobalStyle path={props.location.pathname} />
      <StaticQuery
        query={`${query}`}
        render={data => (
          <div className={props.location.pathname !== '/' ? 'theme-light' : ''}>
            <Navigation
              siteTitle={data.site.siteMetadata.title}
              path={props.location.pathname}
            />
            <Wrapper>{props.children}</Wrapper>
            <FooterMenu path={props.location.pathname}>
              <Link to="/privacy-cookies">Privacy and Cookies</Link>
              <Link to="/terms-conditions">Terms and Conditions</Link>
            </FooterMenu>
            <Ribbon>
              <a href="https://ofform3d.com/" target="_blank">
                ofform3d.com
              </a>
            </Ribbon>
            <TopLeftBorder />
            <BottomRightBorder />
          </div>
        )}
      />
    </ContextProvider>
  )
}

const query = graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
