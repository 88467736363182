import React, { useContext, useState, useRef, useEffect } from 'react'

import { StaticQuery, graphql, Link } from 'gatsby'

import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import {
  Cart,
  ChevronDown,
  ChevronUp,
  Search,
  Heart,
} from 'emotion-icons/boxicons-regular'

import StoreContext from '~/context/StoreContext'
import {
  CartCounter,
  Container,
  MenuWrap,
  Wrapper,
  LogoWrap,
  ToolBar,
  Opener,
} from './styles'

import Logo from '../../svg/logo.svg'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const MenuItem = ({ id, title, hasSubmenu, submenu }) => {
  const [open, setOpen] = useState(false)
  const node = useRef()

  useEffect(() => {
    // add when mounted
    document.addEventListener('mousedown', handleClick)
    // return function to be called when unmounted
    return () => {
      document.removeEventListener('mousedown', handleClick)
    }
  }, [])

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      // inside click
      return
    }
    // outside click
    setOpen(false)
  }

  return (
    <li ref={node}>
      {hasSubmenu ? (
        <Opener onClick={() => setOpen(!open)}>
          {title}
          {open ? <ChevronUp /> : <ChevronDown />}
        </Opener>
      ) : (
        <Link
          to={`/collection/${id}`}
          onClick={() => {
            setOpen(false)
            // console.log('click link')
          }}
        >
          {title}
        </Link>
      )}
      {submenu &&
        submenu.length > 0 &&
        open &&
        renderCollectionsStructured(submenu)}
    </li>
  )
}

const renderCollectionsStructured = items => {
  const menuOptions = items.map(item => {
    return (
      <MenuItem
        key={item.collection_id}
        id={item.collection_id}
        title={item.collection_title}
        hasSubmenu={
          item.collection_submenu && item.collection_submenu.length > 0
            ? true
            : false
        }
        submenu={item.collection_submenu}
      />
    )
  })

  return <ul>{menuOptions}</ul>
}

const renderCollections = items => {
  let menu = []
  let firstLevelCount = -1

  items.forEach((item, i) => {
    let itemObject = {}
    itemObject.collection_id = item.primary.collection_id
    itemObject.collection_title = item.primary.collection_title

    if (item.slice_type === '1st_level') {
      itemObject.collection_submenu = []
      menu.push(itemObject)
      firstLevelCount++
    } else if (item.slice_type === '2nd_level') {
      if (item.items && item.items.length > 0) {
        itemObject.collection_submenu = item.items
      }
      menu[firstLevelCount].collection_submenu.push(itemObject)
    }
  })

  const menuItems = renderCollectionsStructured(menu)

  return <MenuWrap>{menuItems}</MenuWrap>
}

const Navigation = ({ siteTitle, path }) => {
  const [hasItems, quantity] = useQuantity()

  return (
    <Wrapper className={path !== '/' ? 'theme-light' : ''}>
      <Container>
        <LogoWrap>
          <Link to="/">
            <Logo />
          </Link>
        </LogoWrap>

        {/* <StaticQuery
          query={`${mainNavigationQuery}`}
          render={data => {
            const { pages } = data.allPrismicMainMenu.edges[0].node.data
            return <p>ahoj</p>
          }}
        /> */}

        <StaticQuery
          query={`${navigationQuery}`}
          render={data => {
            const {
              body: items,
            } = data.allPrismicCollections.edges[0].node.data

            const { pages } = data.allPrismicMainMenu.edges[0].node.data

            return (
              <>
                <MenuWrap>
                  <ul
                    style={{
                      borderBottom: '2px solid',
                      borderColor: path === '/' ? 'black' : 'white',
                    }}
                  >
                    {pages.map(page => {
                      const link =
                        page.page_link.slug === 'homepage'
                          ? ''
                          : page.page_link.slug
                      return (
                        <li>
                          <Link to={`/${link}`}>{page.page_title}</Link>
                        </li>
                      )
                    })}
                  </ul>
                </MenuWrap>
                {renderCollections(items)}
              </>
            )
          }}
        />

        <ToolBar>
          <li>
            <Link to="/wishlist">
              <Heart color={path === '/' ? 'black' : 'white'} />
            </Link>
          </li>
          <li>
            <Link to="/search">
              <Search color={path === '/' ? 'black' : 'white'} />
            </Link>
          </li>
          <li>
            <Link to="/cart">
              {hasItems && <CartCounter>{quantity}</CartCounter>}
              <Cart color={path === '/' ? 'black' : 'white'} />
            </Link>
          </li>
        </ToolBar>
      </Container>
    </Wrapper>
  )
}

// const mainNavigationQuery = graphql`
//   {
//     allPrismicMainMenu {
//       edges {
//         node {
//           data {
//             pages {
//               page_link {
//                 slug
//               }
//               page_title
//             }
//           }
//         }
//       }
//     }
//   }
// `

const navigationQuery = graphql`
  {
    allPrismicMainMenu {
      edges {
        node {
          data {
            pages {
              page_link {
                slug
              }
              page_title
            }
          }
        }
      }
    }
    allPrismicCollections {
      edges {
        node {
          data {
            body {
              ... on PrismicCollectionsBody1stLevel {
                primary {
                  collection_title
                  collection_id
                }
                slice_type
              }
              ... on PrismicCollectionsBody2ndLevel {
                primary {
                  collection_id
                  collection_title
                }
                items {
                  collection_title
                  collection_id
                }
                slice_type
              }
            }
          }
        }
      }
    }
  }
`

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
