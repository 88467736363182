import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/react'

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = props => {
  return (
    <Global
      {...props}
      styles={css`
        * {
          box-sizing: border-box;
        }
        html,
        body {
          height: 100%;
        }
        html {
          font-family: 'Source Sans Pro', sans-serif;
          font-weight: 300;
          font-size: 62.5%;
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          color: white;
          background: center/cover no-repeat;
          background-attachment: fixed;
          background-image: ${props.path !== '/'
            ? 'url("/background.svg")'
            : 'url("/background-light.svg")'};
          background-color: ${props.path !== '/' ? '#525252' : '#DDDDDD'};
        }
        body {
          margin: 0;
          font-size: 1.7rem;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
        a {
          color: white;
          text-decoration: none;
        }
        img {
          max-width: 100%;
        }
        h1,
        h2 {
          font-size: 1.8rem;
          text-transform: uppercase;
          font-weight: normal;
          margin: 2em 0;
        }
      `}
    />
  )
}

export const Img = styled(Image)`
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: 1080px;
`

export const ContainerNarrow = styled.div`
  margin: 0 auto;
  max-width: 78rem;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`
