import styled, { css } from '@emotion/styled'

import { breakpoints } from '~/utils/styles'

export const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1680px;
  padding: 0 2rem 2rem;
  width: 100%;
  @media (min-width: ${breakpoints.l}px) {
    padding: 0 6rem 6rem;
  }
`

const borderStyles = `
  position: fixed;
  height: 100%;
  width: 100%;
  pointer-events: none;
  z-index: 15;
  display: none;
`

export const TopLeftBorder = styled.div`
  ${borderStyles}
  left: 0;
  top: 0;
  border-left: 3rem solid black;
  border-top: 3rem solid black;
  background: url('/shape-black-left-top.svg') no-repeat;
  background-position: 0 0;
  background-size: auto 10rem;
  @media (min-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const Ribbon = styled.div`
  display: none;
  right: 2.8rem;
  position: fixed;
  top: 50%;
  background-color: black;
  color: white;
  padding: 4rem 1rem;
  writing-mode: vertical-rl;
  font-size: 2rem;
  transform: translateY(-50%);
  @media (min-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const BottomRightBorder = styled.div`
  ${borderStyles}
  right: 0;
  bottom: 0;
  border-right: 3rem solid black;
  border-bottom: 3rem solid black;
  background: url('/shape-black-right-bottom.svg') no-repeat;
  background-position: 100% 100%;
  background-size: auto 10rem;
  @media (min-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const FooterMenu = styled.div`
  font-size: 1.2rem;
  display: flex;
  padding: 2rem;
  @media (min-width: ${breakpoints.l}px) {
    position: fixed;
    bottom: 1rem;
    right: 2.9rem;
    z-index: 20;
    padding: 0;
  }
  a {
    margin-right: 1rem;
    color: black;
    .theme-light & {
      color: white;
    }
    @media (min-width: ${breakpoints.l}px) {
      margin: 0 0 0 1rem;
    }
  }
`
